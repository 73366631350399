import { default as _91_46_46_46slug_93Qv3uKeTLMXMeta } from "/vercel/path0/pages/[...slug].vue?macro=true";
import { default as activitiesgdgHuSWowBMeta } from "/vercel/path0/pages/activities.vue?macro=true";
import { default as email_45sentsnrdGjQhcPMeta } from "/vercel/path0/pages/auth/email-sent.vue?macro=true";
import { default as email_45verifiedftiFXxTkYRMeta } from "/vercel/path0/pages/auth/email-verified.vue?macro=true";
import { default as forgot_45passwordRoBld5gkuaMeta } from "/vercel/path0/pages/auth/forgot-password.vue?macro=true";
import { default as loginILQdBdZzRsMeta } from "/vercel/path0/pages/auth/login.vue?macro=true";
import { default as logout5qQMyX1mvLMeta } from "/vercel/path0/pages/auth/logout.vue?macro=true";
import { default as resend_45emailX0S1aGouM2Meta } from "/vercel/path0/pages/auth/resend-email.vue?macro=true";
import { default as _91token_93RK0OYd6HQiMeta } from "/vercel/path0/pages/auth/reset-password/[token].vue?macro=true";
import { default as signupVML5S8aMRqMeta } from "/vercel/path0/pages/auth/signup.vue?macro=true";
import { default as _91token_938R18x2ZHfxMeta } from "/vercel/path0/pages/auth/verify-email/[token].vue?macro=true";
import { default as chatroomY6wn1gXizLMeta } from "/vercel/path0/pages/chatroom.vue?macro=true";
import { default as complaintrhWH60bIsXMeta } from "/vercel/path0/pages/complaint.vue?macro=true";
import { default as _91id_931oOMxGDnLrMeta } from "/vercel/path0/pages/games/factoes/event/[id].vue?macro=true";
import { default as indexSxn0kHqOoqMeta } from "/vercel/path0/pages/games/factoes/index.vue?macro=true";
import { default as indexpowohKWcNPMeta } from "/vercel/path0/pages/games/fortuna/index.vue?macro=true";
import { default as index5VJlG6PCQRMeta } from "/vercel/path0/pages/games/index.vue?macro=true";
import { default as index87K3rPi3vlMeta } from "/vercel/path0/pages/games/numbers/index.vue?macro=true";
import { default as power_45numbervKgBLvdZ53Meta } from "/vercel/path0/pages/games/numbers/power-number.vue?macro=true";
import { default as indexiepLI10kN0Meta } from "/vercel/path0/pages/games/pacesetter/index.vue?macro=true";
import { default as quiznPUbjplOdyMeta } from "/vercel/path0/pages/games/pacesetter/quiz.vue?macro=true";
import { default as _91subDrawId_93nS596BByQJMeta } from "/vercel/path0/pages/games/quickdraw/[drawId]/[subDrawId].vue?macro=true";
import { default as create_45invite8O22Zke4bGMeta } from "/vercel/path0/pages/games/quickdraw/create-invite.vue?macro=true";
import { default as _91id_93xh0C43ICxEMeta } from "/vercel/path0/pages/games/quickdraw/edit-invite/[id].vue?macro=true";
import { default as indexGjcVSup886Meta } from "/vercel/path0/pages/games/quickdraw/index.vue?macro=true";
import { default as invitemOJxKbqSNiMeta } from "/vercel/path0/pages/games/quickdraw/invite.vue?macro=true";
import { default as typeu42mfoo2SJMeta } from "/vercel/path0/pages/games/quickdraw/type.vue?macro=true";
import { default as homeaAKJ71q3qIMeta } from "/vercel/path0/pages/home.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as notifications0zKhVpcel9Meta } from "/vercel/path0/pages/notifications.vue?macro=true";
import { default as paymentqSZWcJbf8VMeta } from "/vercel/path0/pages/payment.vue?macro=true";
import { default as profilekxSCn5bMpQMeta } from "/vercel/path0/pages/profile.vue?macro=true";
import { default as _91token_93GB31HSLpvOMeta } from "/vercel/path0/pages/reset-pin/[token].vue?macro=true";
import { default as track_45cardfujhZ6Kc40Meta } from "/vercel/path0/pages/track-card.vue?macro=true";
import { default as transactions8JRiMRz2lgMeta } from "/vercel/path0/pages/transactions.vue?macro=true";
import { default as walletNmivtXEuWQMeta } from "/vercel/path0/pages/wallet.vue?macro=true";
import { default as loginBdfQdmQhmTMeta } from "~/pages/auth/login.vue?macro=true";
import { default as signupiCgYRW81cgMeta } from "~/pages/auth/signup.vue?macro=true";
import { default as forgot_45passwordoYqA4RavhUMeta } from "~/pages/auth/forgot-password.vue?macro=true";
import { default as email_45sentVCHkmpVS35Meta } from "~/pages/auth/email-sent.vue?macro=true";
import { default as email_45verifiedoQIF1iyLQpMeta } from "~/pages/auth/email-verified.vue?macro=true";
import { default as _91token_93XSJBlt5zmPMeta } from "~/pages/auth/verify-email/[token].vue?macro=true";
import { default as resend_45emailMQO1VUPG69Meta } from "~/pages/auth/resend-email.vue?macro=true";
import { default as _91token_93OtCSleROPBMeta } from "~/pages/auth/reset-password/[token].vue?macro=true";
import { default as logoutcK4KxrO7X0Meta } from "~/pages/auth/logout.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    component: () => import("/vercel/path0/pages/[...slug].vue")
  },
  {
    name: "activities",
    path: "/activities",
    meta: activitiesgdgHuSWowBMeta || {},
    component: () => import("/vercel/path0/pages/activities.vue")
  },
  {
    name: "auth-email-sent",
    path: "/auth/email-sent",
    meta: email_45sentsnrdGjQhcPMeta || {},
    component: () => import("/vercel/path0/pages/auth/email-sent.vue")
  },
  {
    name: "auth-email-verified",
    path: "/auth/email-verified",
    meta: email_45verifiedftiFXxTkYRMeta || {},
    component: () => import("/vercel/path0/pages/auth/email-verified.vue")
  },
  {
    name: "auth-forgot-password",
    path: "/auth/forgot-password",
    meta: forgot_45passwordRoBld5gkuaMeta || {},
    component: () => import("/vercel/path0/pages/auth/forgot-password.vue")
  },
  {
    name: "auth-login",
    path: "/auth/login",
    meta: loginILQdBdZzRsMeta || {},
    component: () => import("/vercel/path0/pages/auth/login.vue")
  },
  {
    name: "auth-logout",
    path: "/auth/logout",
    component: () => import("/vercel/path0/pages/auth/logout.vue")
  },
  {
    name: "auth-resend-email",
    path: "/auth/resend-email",
    meta: resend_45emailX0S1aGouM2Meta || {},
    component: () => import("/vercel/path0/pages/auth/resend-email.vue")
  },
  {
    name: "auth-reset-password-token",
    path: "/auth/reset-password/:token()",
    meta: _91token_93RK0OYd6HQiMeta || {},
    component: () => import("/vercel/path0/pages/auth/reset-password/[token].vue")
  },
  {
    name: "auth-signup",
    path: "/auth/signup",
    meta: signupVML5S8aMRqMeta || {},
    component: () => import("/vercel/path0/pages/auth/signup.vue")
  },
  {
    name: "auth-verify-email-token",
    path: "/auth/verify-email/:token()",
    meta: _91token_938R18x2ZHfxMeta || {},
    component: () => import("/vercel/path0/pages/auth/verify-email/[token].vue")
  },
  {
    name: "chatroom",
    path: "/chatroom",
    meta: chatroomY6wn1gXizLMeta || {},
    component: () => import("/vercel/path0/pages/chatroom.vue")
  },
  {
    name: "complaint",
    path: "/complaint",
    meta: complaintrhWH60bIsXMeta || {},
    component: () => import("/vercel/path0/pages/complaint.vue")
  },
  {
    name: "games-factoes-event-id",
    path: "/games/factoes/event/:id()",
    meta: _91id_931oOMxGDnLrMeta || {},
    component: () => import("/vercel/path0/pages/games/factoes/event/[id].vue")
  },
  {
    name: "games-factoes",
    path: "/games/factoes",
    meta: indexSxn0kHqOoqMeta || {},
    component: () => import("/vercel/path0/pages/games/factoes/index.vue")
  },
  {
    name: "games-fortuna",
    path: "/games/fortuna",
    meta: indexpowohKWcNPMeta || {},
    component: () => import("/vercel/path0/pages/games/fortuna/index.vue")
  },
  {
    name: "games",
    path: "/games",
    meta: index5VJlG6PCQRMeta || {},
    component: () => import("/vercel/path0/pages/games/index.vue")
  },
  {
    name: "games-numbers",
    path: "/games/numbers",
    meta: index87K3rPi3vlMeta || {},
    component: () => import("/vercel/path0/pages/games/numbers/index.vue")
  },
  {
    name: "games-numbers-power-number",
    path: "/games/numbers/power-number",
    meta: power_45numbervKgBLvdZ53Meta || {},
    component: () => import("/vercel/path0/pages/games/numbers/power-number.vue")
  },
  {
    name: "games-pacesetter",
    path: "/games/pacesetter",
    meta: indexiepLI10kN0Meta || {},
    component: () => import("/vercel/path0/pages/games/pacesetter/index.vue")
  },
  {
    name: "games-pacesetter-quiz",
    path: "/games/pacesetter/quiz",
    meta: quiznPUbjplOdyMeta || {},
    component: () => import("/vercel/path0/pages/games/pacesetter/quiz.vue")
  },
  {
    name: "games-quickdraw-drawId-subDrawId",
    path: "/games/quickdraw/:drawId()/:subDrawId()",
    meta: _91subDrawId_93nS596BByQJMeta || {},
    component: () => import("/vercel/path0/pages/games/quickdraw/[drawId]/[subDrawId].vue")
  },
  {
    name: "games-quickdraw-create-invite",
    path: "/games/quickdraw/create-invite",
    meta: create_45invite8O22Zke4bGMeta || {},
    component: () => import("/vercel/path0/pages/games/quickdraw/create-invite.vue")
  },
  {
    name: "games-quickdraw-edit-invite-id",
    path: "/games/quickdraw/edit-invite/:id()",
    meta: _91id_93xh0C43ICxEMeta || {},
    component: () => import("/vercel/path0/pages/games/quickdraw/edit-invite/[id].vue")
  },
  {
    name: "games-quickdraw",
    path: "/games/quickdraw",
    meta: indexGjcVSup886Meta || {},
    component: () => import("/vercel/path0/pages/games/quickdraw/index.vue")
  },
  {
    name: "games-quickdraw-invite",
    path: "/games/quickdraw/invite",
    meta: invitemOJxKbqSNiMeta || {},
    component: () => import("/vercel/path0/pages/games/quickdraw/invite.vue")
  },
  {
    name: "games-quickdraw-type",
    path: "/games/quickdraw/type",
    meta: typeu42mfoo2SJMeta || {},
    component: () => import("/vercel/path0/pages/games/quickdraw/type.vue")
  },
  {
    name: "home",
    path: "/home",
    meta: homeaAKJ71q3qIMeta || {},
    component: () => import("/vercel/path0/pages/home.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "notifications",
    path: "/notifications",
    meta: notifications0zKhVpcel9Meta || {},
    component: () => import("/vercel/path0/pages/notifications.vue")
  },
  {
    name: "payment",
    path: "/payment",
    meta: paymentqSZWcJbf8VMeta || {},
    component: () => import("/vercel/path0/pages/payment.vue")
  },
  {
    name: "profile",
    path: "/profile",
    meta: profilekxSCn5bMpQMeta || {},
    component: () => import("/vercel/path0/pages/profile.vue")
  },
  {
    name: "reset-pin-token",
    path: "/reset-pin/:token()",
    meta: _91token_93GB31HSLpvOMeta || {},
    component: () => import("/vercel/path0/pages/reset-pin/[token].vue")
  },
  {
    name: "track-card",
    path: "/track-card",
    meta: track_45cardfujhZ6Kc40Meta || {},
    component: () => import("/vercel/path0/pages/track-card.vue")
  },
  {
    name: "transactions",
    path: "/transactions",
    meta: transactions8JRiMRz2lgMeta || {},
    component: () => import("/vercel/path0/pages/transactions.vue")
  },
  {
    name: "wallet",
    path: "/wallet",
    meta: walletNmivtXEuWQMeta || {},
    component: () => import("/vercel/path0/pages/wallet.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginBdfQdmQhmTMeta || {},
    component: () => import("~/pages/auth/login.vue")
  },
  {
    name: "signup",
    path: "/signup",
    meta: signupiCgYRW81cgMeta || {},
    component: () => import("~/pages/auth/signup.vue")
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    meta: forgot_45passwordoYqA4RavhUMeta || {},
    component: () => import("~/pages/auth/forgot-password.vue")
  },
  {
    name: "email-sent",
    path: "/email-sent",
    meta: email_45sentVCHkmpVS35Meta || {},
    component: () => import("~/pages/auth/email-sent.vue")
  },
  {
    name: "email-verified",
    path: "/email-verified",
    meta: email_45verifiedoQIF1iyLQpMeta || {},
    component: () => import("~/pages/auth/email-verified.vue")
  },
  {
    name: "verify-email",
    path: "/verify-email/:token",
    meta: _91token_93XSJBlt5zmPMeta || {},
    component: () => import("~/pages/auth/verify-email/[token].vue")
  },
  {
    name: "resend-mail",
    path: "/resend-mail",
    meta: resend_45emailMQO1VUPG69Meta || {},
    component: () => import("~/pages/auth/resend-email.vue")
  },
  {
    name: "reset-password",
    path: "/reset-password/:token",
    meta: _91token_93OtCSleROPBMeta || {},
    component: () => import("~/pages/auth/reset-password/[token].vue")
  },
  {
    name: "logout",
    path: "/logout",
    component: () => import("~/pages/auth/logout.vue")
  }
]